import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';

const PoojaReq = () => {
    return (
        <>
            <SEO title="Pooja Request Form" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Pooja Request Form</h3>
                <Pageheader />
                <iframe title="poojareq" src="https://docs.google.com/forms/d/e/1FAIpQLScjt-XjlUt6r4sNAXj2zeTUGpk7uz8tdy6qD_iqYvy8_W28jQ/viewform?embedded=true" width="100%" style={{ minHeight: "2000px" }} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </MDBContainer>
        </>
    );
};

export default PoojaReq;